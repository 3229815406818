export function disableBodyScroll(): void {
    setTimeout(() => {
        document.body.style.overflow = 'hidden';
    });
};

export function enableBodyScroll(): void {
    setTimeout(() => {
        document.body.style.overflow = 'auto';
    });
}