import React from 'react';
import './carousel.css';
import { enableBodyScroll, disableBodyScroll } from '../scripts/scrollLock';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';

interface CarouselProps {
    showIndicators: boolean;
    showArrow: boolean;
    defaultIndex: number;
    arrowTopOffset?: string;
    arrowHorOffset?: string;
    indicatorBottomOffset?: string;
    onClick: (idx: number) => void;
}

export default class Carousel extends React.Component<CarouselProps> {
    private _currentIndex: number = 0;
    private _swipeWrapper: HTMLDivElement | null = null;
    private _children: HTMLCollection | undefined;
    private _touching: boolean = false;
    private _swiping: boolean = false;
    private _scrolling: boolean = false;
    private _startX: number = 0;
    private _viewportW: number | undefined;
    private _scrollCallback: any;

    componentDidMount(): void {
        this._init();
        window.addEventListener('resize', this._onResize);
        window.addEventListener('orientationchange', this._onResize);
        // need to do this for now to handle change w size of the children
        setTimeout(() => {
            this._onResize();
        }, 1000);
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this._onResize);
        window.removeEventListener('orientationchange', this._onResize);
    }

    private _clamp(num: number): number {
        if (this._children) {
            const min = 0;
            const max = this._children.length - 1;
            return num <= min ? min : num >= max ? max : num;
        }
        return 0;
    }

    private _onResize = (): void => {
        this._viewportW = this._swipeWrapper?.offsetWidth;
        this._translateChildren();
    }

    private _init(): void {
        this._children = this._swipeWrapper?.children;
        if (this._children) {
            for (let i = 0; i < this._children.length; i++) {
                this._children[i].classList.add('absolute');
            }
        }
        this._currentIndex = this._clamp(this.props.defaultIndex);
        this._translateChildren();
        this._onResize();
        // detect body scroll
        document.body.onscroll = () => {
            this._scrolling = true;
            // Clear our timeout throughout the scroll
            window.clearTimeout(this._scrollCallback);
            // Set a timeout to run after scrolling ends
            this._scrollCallback = setTimeout(() => {
                this._scrolling = false;
            }, 66);
        }
    }

    private _translateChildren(delta = 0, duration = 0): void {
        if (this._children && this._viewportW) {
            for (let i = 0; i < this._children.length; i++) {
                let x = this._viewportW * i - this._viewportW * this._currentIndex;
                x += delta;
                (this._children[i] as HTMLElement).style.cssText = this._translate(x, duration);
            }
        }
    }

    private _translate(x: number, duration: number = 0): string {
        return `
    -webkit-transition-duration: ${duration}s;
    transition-duration: ${duration}s;
    -webkit-transform: translate3d(${x}px, 0, 0);
    -ms-transform: translate3d(${x}, 0, 0);`;
    }

    private _onSwiping = (e: TouchEvent | MouseEvent): void => {
        e.stopImmediatePropagation()
        e.cancelable && e.preventDefault();
        // get the delta touch x from the start x
        if (this._touching) {
            let curX = 0;
            if (e instanceof MouseEvent) {
                curX = e.pageX;
            } else {
                curX = e.touches[0].pageX;
            }
            const deltaX = this._startX - curX;
            if (!this._swiping) {
                if (Math.abs(deltaX) > 10) {
                    this._swiping = true;
                    disableBodyScroll();
                }
            } else {
                // translate immediately based on the delta x
                this._translateChildren(-deltaX);
            }
        }
    }

    private _onTouchStart = (e: TouchEvent | MouseEvent): void => {
        e.stopImmediatePropagation()
        e.cancelable && e.preventDefault();
        // set touching and the initial start pos        
        if (e instanceof MouseEvent) {
            this._startX = e.pageX;
        } else {
            this._startX = e.touches[0].pageX;
        }
        this._touching = true;
    }

    private _onTouchEnd = (e: MouseEvent | TouchEvent): void => {
        e.stopImmediatePropagation();
        e.cancelable && e.preventDefault();
        // get the delta touch x from the start x
        const curX: number = e instanceof MouseEvent ? e.pageX : e.changedTouches[0].pageX;
        const dX: number = this._startX - curX;
        // not swiping, means it's a click
        if (this._touching && !this._swiping && !this._scrolling) {
            this.props.onClick(this._currentIndex);
            return;
        }
        // check if cur touch x is greater than 20px
        if (Math.abs(dX) > 20) {
            this._currentIndex = this._currentIndex + (dX / Math.abs(dX));
            // clamp so that the index wont exceed 0 or max length
            this._currentIndex = this._clamp(this._currentIndex);
        }
        // animate the swipe movement for each children
        this._translateChildren(0, 0.5);
        // force update render indicator change
        setTimeout(() => {
            this.setState({});
        }, 0.5);
        // reset
        this._touching = this._swiping = this._scrolling = false;
        enableBodyScroll();
    }

    private _setNext = (): void => {
        this._currentIndex = this._clamp(this._currentIndex + 1);
        // animate the swipe movement for each children
        this._translateChildren(0, 0.5);
        // force update render indicator change
        setTimeout(() => {
            this.setState({});
        }, 0.5);
    }

    private _setPrev = (): void => {
        this._currentIndex = this._clamp(this._currentIndex - 1);
        // animate the swipe movement for each children
        this._translateChildren(0, 0.5);
        // force update render indicator change
        setTimeout(() => {
            this.setState({});
        }, 0.5);
    }

    render() {
        const { showIndicators, showArrow, children, arrowTopOffset, arrowHorOffset, indicatorBottomOffset } = this.props;
        return (
            <div className="full relative overflow-hidden">
                <div className="swipe-item-wrapper full relative" ref={(e) => this._swipeWrapper = e}>
                    {this.props.children}
                </div>
                <div
                    className="swipe-handler cursor-pointer"
                    onTouchStart={(e) => this._onTouchStart(e.nativeEvent)}
                    onMouseDown={(e) => this._onTouchStart(e.nativeEvent)}
                    onMouseMove={(e) => this._onSwiping(e.nativeEvent)}
                    onTouchMove={(e) => this._onSwiping(e.nativeEvent)}
                    onTouchEnd={(e) => this._onTouchEnd(e.nativeEvent)}
                    onMouseUp={(e) => this._onTouchEnd(e.nativeEvent)}
                />
                {
                    showIndicators &&
                    <CarouselIndicator bottomOffset={indicatorBottomOffset} currentIndex={this._currentIndex} count={React.Children.count(children)} />
                }
                {
                    showArrow &&
                    <FaArrowAltCircleLeft style={{ top: arrowTopOffset || '40%', left: arrowHorOffset || '1rem' }} onClick={() => this._setPrev()} size={36} color="white" className="hidden md:block cursor-pointer absolute"></FaArrowAltCircleLeft>
                }
                {
                    showArrow &&
                    <FaArrowAltCircleRight style={{ top: arrowTopOffset || '40%', right: arrowHorOffset || '1rem' }} onClick={() => this._setNext()} size={36} color="white" className="hidden md:block cursor-pointer absolute"></FaArrowAltCircleRight>
                }
            </div >
        );
    }
}

interface IndicatorProps {
    currentIndex: number;
    count: number;
    bottomOffset?: string;
}

const CarouselIndicator: React.FunctionComponent<IndicatorProps> = (props) => {
    return (
        <div className="swipe-indicator" style={{ bottom: props.bottomOffset || '1rem' }}>
            {
                Array.from(Array(props.count).keys()).map(
                    (i) => <span key={i} className={`dot ${props.currentIndex === i ? 'is-active' : ''}`} />
                )
            }
        </div>
    );
}
