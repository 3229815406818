import React, { ReactElement } from "react"
import { navigate } from "gatsby"
import Header from "../components/header"
import Carousel from "../components/carousel"
import Footer from "../components/footer"
import { AppInfo } from "../components/appInfo"
import { topBanners, bottomBanners, getImgPath } from "../models/models"

function Index(): ReactElement {
  return (
    <>
      <Header page={"Home"} />
      <div className="w-full flex flex-col items-center justify-center">
        <div className="w-full header-carousel-sm md:header-carousel-md lg:header-carousel-xl">
          <Carousel
            showIndicators
            showArrow={false}
            indicatorBottomOffset="2rem"
            arrowHorOffset="2rem"
            arrowTopOffset="45%"
            defaultIndex={0}
            onClick={idx => navigate(`/app/${topBanners[idx].tag}`)}
          >
            {topBanners.map((c, i) => (
              <div key={i} className="md:p-6">
                <img
                  className="w-full md:rounded-lg"
                  src={getImgPath(c.src)}
                  alt={c.tag}
                />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="px-10 md:px-0 md:w-1/3 py-8 flex flex-col justify-center items-center">
          <p className="font-bold text-center text-2xl md:text-4xl">
            Fun Brain Games for All
          </p>
        </div>
        <div className="w-full mid-carousel-sm md:mid-carousel-md lg:mid-carousel-lg">
          <Carousel
            showArrow
            arrowHorOffset="3rem"
            arrowTopOffset="25%"
            defaultIndex={0}
            showIndicators
            onClick={idx => navigate(`/app/${bottomBanners[idx].tag}`)}
          >
            {bottomBanners.map((c, i) => (
              <div key={i} className="w-full px-6 md:px-10 py-4">
                <AppInfo {...c} />
              </div>
            ))}
          </Carousel>
        </div>
        <button
          onClick={() => navigate("/apps")}
          className="focus:outline-none my-10 rounded-full py-2 px-6 bg-blue-400 hover:bg-blue-600 text-white text-xl transform transition duration-500 ease-in-out hover:scale-105"
        >
          See All Our Apps
        </button>
      </div>
      <Footer />
    </>
  )
}

export default Index
